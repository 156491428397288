import React from 'react'
import Navbar from './Navbar'
import Topbar from './Topbar'

export default function Header() {
  return (
    <>
        <Topbar/>
        <Navbar/>
    </>
  )
}
